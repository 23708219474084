(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('samplelog', {
            parent: 'entity',
            url: '/samplelog?page&sort&search&productCategoryId&items&id&orderId&sampleName&lotNumber&metrcId&categoryIdList&orderType&thcLower&thcUpper&cbdLower&cbdUpper&isPass&isFail&isUndetermined&isWeedmapsSuccess&isWeedmapsFail' +
            	'&isWeedmapsNone&visibility&completeDateLower&completeDateUpper&estimatedDueDateLower&estimatedDueDateUpper&galleryView&starred&isInProgress&isCompleted',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Samples'
            },
            views: {
                'content@': {
                    templateUrl: 'app/pages/sample-log/sample_log.html',
                    controller: 'SampleLogController',
                    controllerAs: 'vm'
                }
            },
            params: {
                page: {
                    value: '1',
                    squash: true
                },
                sort: {
                    value: 'id,desc',
                    squash: true
                },
                search: null,

                productCategoryId: {
                    value: '0',
                    squash: true
                },
                items: null,
                /* Filter params */
        		id: null,
        		orderId: null,
        		sampleName: null,
        		lotNumber: null,
        		metrcId: null,
        		categoryIdList: null,
        		category: null,
        		subCategory: null,
        		orderType: null,
        		thcLower: null,
        		thcUpper: null,
        		cbdLower: null,
        		cbdUpper: null,
        		isPass: null,
        		isFail: null,
        		isUndetermined: null,
        		isWeedmapsSuccess: null,
        		isWeedmapsFail: null,
        		isWeedmapsNone: null,
        		visibility: null,
        		completeDateLower: null,
        		completeDateUpper: null,
        		estimatedDueDateLower: null,
        		estimatedDueDateUpper: null,
        		galleryView: null,
                starred: null,
                isInProgress: null,
                isCompleted: null,
                sampleMatrix: null
            },
            resolve: {
                pagingParams: ['$stateParams', 'PaginationUtil', function ($stateParams, PaginationUtil) {

                    return {
                    	/* Paging Params */
                        page: PaginationUtil.parsePage($stateParams.page),
                        sort: $stateParams.sort,
                        predicate: PaginationUtil.parsePredicate($stateParams.sort),
                        ascending: PaginationUtil.parseAscending($stateParams.sort),
                        search: $stateParams.search,
                        items: $stateParams.items,
                        /* Filter Params */
                        id: $stateParams.id ? parseInt($stateParams.id) : null,
                        orderId: $stateParams.orderId ? parseInt($stateParams.orderId) : null,
                        sampleName: $stateParams.sampleName,
                        lotNumber: $stateParams.lotNumber,
                        metrcId: $stateParams.metrcId,
                        categoryIdList: $stateParams.categoryIdList,
                        category: $stateParams.category,
                        subCategory: $stateParams.subCategory,
                        orderType: $stateParams.orderType,
                        thcLower: $stateParams.thcLower ? parseInt($stateParams.thcLower) : null,
                        thcUpper: $stateParams.thcUpper ? parseInt($stateParams.thcUpper) : null,
                        cbdLower: $stateParams.cbdLower ? parseInt($stateParams.cbdLower) : null,
                        cbdUpper: $stateParams.cbdUpper ? parseInt($stateParams.cbdUpper) : null,
                        isPass: $stateParams.isPass,
                        isFail: $stateParams.isFail,
                        isUndetermined: $stateParams.isUndetermined,
                        isWeedmapsSuccess: $stateParams.isWeedmapsSuccess,
                        isWeedmapsFail: $stateParams.isWeedmapsFail,
                        isWeedmapsNone: $stateParams.isWeedmapsNone,
                        visibility: $stateParams.visibility,
                        completeDateLower: $stateParams.completeDateLower,
                        completeDateUpper: $stateParams.completeDateUpper,
                        estimatedDueDateLower: $stateParams.estimatedDueDateLower,
                        estimatedDueDateUpper: $stateParams.estimatedDueDateUpper,
                        galleryView: $stateParams.galleryView,
                        starred: $stateParams.starred,
                        isInProgress: $stateParams.isInProgress,
                        isCompleted: $stateParams.isCompleted,
                        sampleMatrix: $stateParams.sampleMatrix
                    }
                }],
            }
        })
        .state('samplelog-detail', {
            parent: 'samplelog',
            url: '/samplelog/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Sample'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/sample/sample-detail.html',
                    controller: 'SampleDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Sample', function($stateParams, Sample) {
                    return Sample.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'sample',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('samplelog-detail.edit', {
            parent: 'samplelog-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sample/sample-dialog.html',
                    controller: 'SampleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Sample', function(Sample) {
                            return Sample.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('samplelog.new', {
            parent: 'samplelog',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sample/sample-dialog.html',
                    controller: 'SampleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                name: null,
                                lotBatchNumber: null,
                                residualSolvent: null,
                                other: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('sample', null, { reload: 'sample' });
                }, function() {
                    $state.go('sample');
                });
            }]
        })
        .state('samplelog.edit', {
            parent: 'samplelog',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sample/sample-dialog.html',
                    controller: 'SampleDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Sample', function(Sample) {
                            return Sample.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sample', null, { reload: 'sample' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('samplelog.view', {
            parent: 'samplelog',
            url: '/{id}/view',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/sample-log/samplelog-dialog.html',
                    controller: 'SampleLogDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Sample', function(Sample) {
                            return Sample.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sample', null, { reload: 'sample' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('samplelog.delete', {
            parent: 'samplelog',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/sample/sample-delete-dialog.html',
                    controller: 'SampleDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Sample', function(Sample) {
                            return Sample.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('sample', null, { reload: 'sample' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('samplelog.editcolumns', {
            parent: 'samplelog',
            url: '/samples/columns',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams','$state', '$uibModal', function($stateParams,$state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/pages/sample-log/samplelog-columns-dialog.html',
                    controller: 'DialogSampleColumns',
                    controllerAs: 'vm',
                    size: 'lg'
                }).result.then(function() {
                	$state.go('samplelog', {}, {reload: true});
                }, function() {
                    $state.go('samplelog', {}, {reload: true});
                });
            }]
        });
    }


})();
