(function() {
    'use strict';

    angular
        .module('anrescowebApp')
        .controller('HomeController', HomeController);

    HomeController.$inject = ['$scope','$rootScope','$window', '$localStorage', 'Customer', 'Principal', 'LoginService', '$state', '$filter','Auth',
                                'ProfileService','CustomerByUser','CustomerOrder', 'Contact2ForUser', 'Invoice','Sample', 'SampleFilter', 'ProductCategory', 'Config', '$translate'];

    function HomeController ($scope,$rootScope, $window, $localStorage, Customer, Principal, LoginService, $state, $filter, Auth,
                            ProfileService,CustomerByUser,CustomerOrder, Contact2ForUser, Invoice, Sample, SampleFilter, ProductCategory, Config, $translate) {

        var vm = this;
        vm.currentLanguage = $translate.proposedLanguage();
        angular.element(document).ready(function() {
            angular.element('#wrap').addClass("wrap");
        });

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;

        });
        var current_user = JSON.parse(localStorage.getItem('jhi-oldUserLogin'))
        if(current_user){
            vm.current_user = current_user.replace(/\"/g, "")
        }
        vm.login = login;

        vm.logout = logout;
        vm.customer_type = null;
        vm.account_balance = 0;
        vm.primary_rep = {
            name: 'Amanda Eisenberg',
            email: 'amanda@anresco.com',
            phone: '415-684-1535'
        }

        if(!vm.isAuthenticated()){
            $localStorage.$reset()
            $state.go('login');
        }

        getCustomerData();

        getCustomerOrdersDate();

        vm.visitOurSite = function () {

            $window.open('//www.anresco.com');
        }

        setPageTitle();

        vm.selectDaterangePassFailChart = selectDaterangePassFailChart;
        google.charts.load('current', {packages: ['corechart']});
        google.charts.setOnLoadCallback(initPassFailChart);

        getQueuedSamples();

        // Calendar Widget
        vm.blackoutDates = []
       
        vm.monthString = moment().format('MMMM YYYY');
        vm.events = [];
        vm.filterSamples = function() {
        	vm.calendarLoading = true;

        	SampleFilter.calendar(createQueryParams(), function(samples){
        		vm.calendarLoading = false;
                vm.calendarLoadError = false;

                var start_date = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.start._d);
				var end_date = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.end._d)

				for(var m = moment(start_date); m.diff(end_date, 'days') <= 0; m.add(1, 'days')) {
                    if([0, 6].includes(m.day()) || vm.holidays.includes(m.format('YYYY-MM-DD'))) {
                        vm.blackoutDates.push(m.format('YYYY-MM-DD'));
                    }
                }

        		var events = [];
        		if (samples && samples.length) {
        			var dayMap = {}
        			for (var i = 0; i < samples.length; i++) {
        				var day = moment(samples[i].dueDate).toDate();
        				var dayKey = samples[i].dueDate;
        				if (!dayMap[dayKey]) dayMap[dayKey] = []
        				var event = {
        						id: samples[i].id,
        						name: samples[i].name ? samples[i].name : '',
        						title: samples[i].id + ' ' + samples[i].name,
        						start: day,
        						end: day,
        						allDay: true,
        						hash: samples[i].hash
        				}
        				dayMap[dayKey].push(event)
        			}
        			for (var k in dayMap) {
    					dayMap[k].push({
    						id: '-1',
    						name: 'Nothing',
    						title: 'Nothing',
    						start: dayMap[k][0].start,
    						end: dayMap[k][0].end,
    						allDay: true,
    						hash: null
    					});
    					events = events.concat(dayMap[k]);
        			}
        		}
        		vm.events[0] = events;
        		$('#estimatedDueDateCalendar').fullCalendar('getView').dayGrid.renderGrid();


        	}, function(error) {
        		vm.calendarLoading = false;
        		vm.calendarLoadError = true;
        	});
        }

        vm.filterOptions = [
        	{text: "R&D", value: 'R&D'},
        	{text: "Compliance", value: 'COMPLIANCE'}
        ];
        var categoryNames = [];
        ProductCategory.query(function(categories){
        	if (!categories) return;
        	for (var i = 0; i < categories.length; i++){
        		vm.filterOptions.push({text: categories[i].name, value: categories[i].id});
        		categoryNames.push(categories[i].name);
        	}
        });
        vm.backgroundImages = {
			'2023-01-01': 'content/images/calendar_images/NewYear.jpg',
			'2023-02-15': 'content/images/calendar_images/PresidentsDay.jpg',
			'2023-05-31': 'content/images/calendar_images/MemorialDay.jpg',
			'2023-07-04': 'content/images/calendar_images/4thJuly.jpg',
			'2023-09-06': 'content/images/calendar_images/LaborDay.jpg',
            '2023-11-25': 'content/images/calendar_images/thanksGiving.png',
			'2023-11-26': 'content/images/calendar_images/thanksGiving1.png',
			'2023-12-24': 'content/images/calendar_images/Christmas.jpg',
			'2023-12-25': 'content/images/calendar_images/Christmas1.jpg',
			'2023-12-31': 'content/images/calendar_images/newYearEve.jpg',

			'2024-01-01': 'content/images/calendar_images/NewYear.jpg',
			'2024-02-15': 'content/images/calendar_images/PresidentsDay.jpg',
			'2024-05-27': 'content/images/calendar_images/MemorialDay.jpg',
			'2024-07-04': 'content/images/calendar_images/4thJuly.jpg',
			'2024-09-02': 'content/images/calendar_images/LaborDay.jpg',
			'2024-11-28': 'content/images/calendar_images/thanksGiving.png',
			'2024-11-29': 'content/images/calendar_images/thanksGiving1.png',
			'2024-12-24': 'content/images/calendar_images/Christmas.jpg',
			'2024-12-25': 'content/images/calendar_images/Christmas1.jpg',
			'2024-12-31': 'content/images/calendar_images/newYearEve.jpg',

			'2025-01-01': 'content/images/calendar_images/NewYear.jpg',
			'2025-02-17': 'content/images/calendar_images/PresidentsDay.jpg',
			'2025-05-26': 'content/images/calendar_images/MemorialDay.jpg',
			'2025-07-04': 'content/images/calendar_images/4thJuly.jpg',
			'2025-09-01': 'content/images/calendar_images/LaborDay.jpg',
			'2025-11-27': 'content/images/calendar_images/thanksGiving.png',
			'2025-11-28': 'content/images/calendar_images/thanksGiving1.png',
			'2025-12-24': 'content/images/calendar_images/Christmas.jpg',
			'2025-12-25': 'content/images/calendar_images/Christmas1.jpg',
			'2025-12-31': 'content/images/calendar_images/newYearEve.jpg'
			
		};

        getOrdersOpenSubmission();

        function setCalendarOptions() {
            vm.calendarOptions = {
                    defaultView: 'week',
                    firstDay: 1,
                    views: {
                        week: {
                            type: 'basic',
                            duration: {
                                weeks: 3
                            }
                        }
                    },
                    height: 300,
                    dayNames: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    dayNamesShort: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    eventLimit: 1,
                    eventLimitClick: function(cellInfo, jsEvent) {
                        var modalParams = {samples: []};
                        for (var i = 0; i < cellInfo.segs.length; i++){
                            var def = cellInfo.segs[i].footprint.eventInstance.def;
                            modalParams.samples.push({
                                id: def.id,
                                name: def.miscProps.name,
                                hash: def.miscProps.hash
                            });
                        }
                        modalParams.date = cellInfo.date;
                        modalParams.queryParams = createQueryParams();
                        $state.go('home.dayModal', modalParams);
                    },
                    eventLimitText: function(count) {
                        return count - 1;
                    },
                    eventRender: function(event, element) {
                        return '<div class="calendar-sample-event"><strong>' + event.id + '</strong> ' + event.name + '</div>'
                    },
                    eventClick: function(event, jsEvent, view) { $state.go('public', {hash: event.hash}); },
                    viewRender: vm.filterSamples,
                    dayRender: function(date, cell) {
                        cell.append('<div class="calendar-day-no-samples">No Samples</div>');
                        var currentDate = date.format('YYYY-MM-DD');
					if (currentDate in vm.backgroundImages) {
						var cellId = 'cell_' + currentDate;
						cell.attr('id', cellId);
						var backgroundImageUrl = vm.backgroundImages[currentDate];
						cell.css('background-image', 'url(' +backgroundImageUrl+ ')');
						cell.css('object-fit','contain')
						cell.css('background-size','100% 100%');
						cell.find('div.calendar-day-no-samples').css('opacity', '0');
						

						// Remove "Closed" text on days with events
						var dayOfWeek = moment(date).day();
						if (dayOfWeek === 0 || dayOfWeek === 6) {
							cell.find('.fc-day-top').text('');
							cell.find('div.calendar-day-no-samples').remove();
						}
					}
                    },
                    eventAfterAllRender: function(view) {
                        $('#estimatedDueDateCalendar').fullCalendar('clientEvents', function(event) {
                            var td = $('td.fc-day[data-date="' + event.start.format('YYYY-MM-DD') + '"]');
                            td.find('div:first').remove();
                        });

                        var blackout = null;
                        var blackoutCount = null;
                        vm.holidayDates = Object.keys(vm.backgroundImages);
                        var name = " "
                        vm.blackoutDates.forEach( function(date) {
                            blackout = $('td.fc-day[data-date="' + date + '"]');
                            blackout.css('background-color', '#f7f7f7');
                            blackout.find('div.calendar-day-no-samples').css('color', '#c5ced1').text(`Closed\r\n${name}`);
                        });
                        vm.holidayDates.forEach(function(date){
                            blackout = $('td.fc-day-top[data-date="' + date + '"]');
                            blackout.css('visibility', 'hidden');
                        })
                    }
            }
        }
                     

        function createQueryParams() {
        	var params = {};
        	params.estimatedDueDateLower = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.start._d);
        	params.estimatedDueDateUpper = convertLocalDateToServer($('#estimatedDueDateCalendar').fullCalendar('getCalendar').view.end._d);

        	for (var i = 0; i < vm.filterOptions.length; i++) {
        		if (!vm.filterOptions[i].selected) continue;

        		var text = vm.filterOptions[i].text;
        		var value = vm.filterOptions[i].value;

        		// Parse category filter
        		if (categoryNames.indexOf(text) !== -1) {
        			if (params.categoryIdList === undefined) {
        				params.categoryIdList = [value];
        			} else {
        				params.categoryIdList.push(value);
        			}
        		// Parse order type filter
        		} else if (text === 'Compliance') {
        			params.orderType = params.orderType === 'R&D' ? null : value;
        		} else if (text === 'R&D') {
        			params.orderType = params.orderType === 'COMPLIANCE' ? null : value;
        		}
        	}
        	return params;
        }

        function setPageTitle(){
            $rootScope.pageTitle = "Welcome";
        }

        function getCustomerData() {
            Config.holidays({},
                function(data) {
                    var arrayOfHolidays = []
                    data.holidays.forEach(element => {
                        for(var i = 1924; i <= 9999; i++){
                            if (element.date) {
                                var s = element.date.replace('2021',i.toString())
                                arrayOfHolidays.push(s);
                            }
                        } 
                    })
                    vm.holidays = arrayOfHolidays
                    // vm.holidays = data.holidays
                    setCalendarOptions();
                },
                function(e) {
                    console.log(e);
                }
            );

            CustomerByUser.get({}, onSuccessCustomer, onErrorCustomer);
            function onSuccessCustomer(data, headers) {
            	if (data.account_balance == 'null'){
            		data.account_balance = null;
            	}
                vm.customerData = data;

                $rootScope.customer = {
                    customer_type: data.businessType && data.businessType.name ? data.businessType.name : 'Cannabis',
                    company_discount: data.company_discount ? data.company_discount : null,
                    customer_id: data.id ? data.id : null
                }

                $localStorage.customer = {
                    id: data.id,
                    dbaName: data.dbaName,
                }

                vm.customer_type = $rootScope.customer.customer_type;

                if(['cannabis', 'hemp'].includes(vm.customer_type.toLowerCase())) {
                    vm.primary_rep = {
                        name: 'Lillian Howard',
                        email: 'lillian@anresco.com',
                        phone: '831-421-1371'
                    }
                };

                if(data.primary_anresco_email || data.primary_anresco_rep){
                    vm.primary_rep = {
                        name: data.primary_anresco_rep,
                        email: data.primary_anresco_email,
                        phone: data.primary_anresco_phone
                    };
                };
                getCurrentContact();
            }
            function onErrorCustomer(error) {
                console.log(error);
            }
        }

        function getOrdersOpenSubmission() {
        	CustomerOrder.openSubmissions({}, onSuccessSubmissions, onErrorSubmissions);

        	function onSuccessSubmissions(data) {
        		vm.openSubmissions = data.openSubmissions;
            }

        	function onErrorSubmissions(error) {
                console.log(error);
            }
        }

        function refreshStorage(){
            $localStorage.oldUserFirstName = null
            $localStorage.oldUserLogin = null
            $localStorage.oldUserLogin = JSON.stringify(vm.contact.login);
            $localStorage.oldUserFirstName = JSON.stringify(vm.contact.first_name);
        }

        function getUnpaidInvoices() {
            Invoice.getUnpaid({},
                function(data) {
                    data.invoices.forEach( function(invoice) {
                        vm.account_balance += invoice.outstanding_amount;
                    });
                },
                function(e) {
                    console.log(e);
                }
            );
        }



        function getCurrentContact() {
            var contact_customer_index = null;

            Contact2ForUser.get({}, onSuccessCustomer, onErrorCustomer);
            function onSuccessCustomer(data, headers) {
                vm.contact = data;

                if(data.contactCustomers && vm.customerData.id) {
                    contact_customer_index = findWithAttr(data.contactCustomers, 'customer_id', vm.customerData.id);
                    if(contact_customer_index != -1) {
                        vm.contact.receive_coa = data.contactCustomers[contact_customer_index].receive_coa;
                        vm.contact.receive_invoice = data.contactCustomers[contact_customer_index].receive_invoice;
                    }
                }

                if(vm.contact.receive_invoice) {
                    getUnpaidInvoices();
                }

                refreshStorage();
                $rootScope.userFirstName = vm.contact.first_name;
            }
            function onErrorCustomer(error) {
                console.log(error);
            }
        }

        function findWithAttr(array, attr, value) {
            for(var i = 0; i < array.length; i += 1) {
                if(array[i][attr] === value) {
                    return i;
                }
            }
            return -1;
        }

        function getCustomerOrdersDate() {
            CustomerOrder.query({
                page: 0,
                size: 5,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = ['dateCreated' + ',' + 'desc'];
                return result;
            }
            function onSuccess(data, headers) {
                vm.customerOrders = data;
            }
            function onError(error) {

            }
            Invoice.query({
                page: 0,
                size: 5,
                sort: sortInv()
            }, onSuccessInv, onErrorInv);
            function sortInv() {
                var result = ['invoiceDate' + ',' + 'desc'];
                return result;
            }
            function onSuccessInv(data, headers) {
                vm.invoices = data;
            }
            function onErrorInv(error) {
                console.log(error);
            }

        }

        function login() {
            collapseNavbar();
            $state.go('login');
        }

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

		// forces the 'resize' callback
		// to wait setTimeout ms value time before
		// considering a resize as 'complete'

		function onResize(c,t) {
			onresize = function() { // refers to window.onresize callback
				clearTimeout(t);
				t = setTimeout(c,250);
				};
			return c;
			};

		// redraws pie chart
		onResize(function(){
			initPassFailChart();
            selectDaterangePassFailChart();
            drawPassFailChart(vm.passFailData);
		});

		// necessary to redraw chart
		vm.passFailData = null;

        function initPassFailChart() {
            vm.dateranges = [
            	'Last 3 Months',
            	'Last 6 Months',
            	'Year To Date',
            	'All'
            ];

            vm.selectedDaterangePassFailChart = vm.dateranges[0];

            Sample.passFailCount({daterange: vm.selectedDaterangePassFailChart}, function(passFailData){
            	if ((passFailData.Passing + passFailData.Failing + passFailData.Other) == 0) {
        			angular.element('#pie-chart').html("<div style='text-align:center;'>"+$translate.instant('No Statistics Available For This Time Range')+"</div>")
        			return
        		}

        		angular.element('#pie-chart').html("")
            	vm.passFailData = passFailData;
            	drawPassFailChart(vm.passFailData);
            });
        }

        function selectDaterangePassFailChart() {
        	Sample.passFailCount({daterange: vm.selectedDaterangePassFailChart}, function(passFailData){
        		if ((passFailData.Passing + passFailData.Failing + passFailData.Other) == 0) {
        			angular.element('#pie-chart').html("<div style='text-align:center;'>"+$translate.instant('No Statistics Available For This Time Range')+"</div>")
        			return
        		}

        		angular.element('#pie-chart').html("");
				vm.passFailData = passFailData;
        		drawPassFailChart(vm.passFailData);
        	});
        }

        function drawPassFailChart(passFailData) {
            // only draw if on home page
            if ($rootScope.toState.name == 'home' && passFailData) {
                var data = new google.visualization.DataTable();
                data.addColumn('string', 'Status');
                data.addColumn('number', 'Count');

                var colors = [
                    '#a6c65c', // Passing
                    '#c86968', // Failing
                    '#415168' // Other
                ];

                data.addRow(['Passing', passFailData.Passing ? passFailData.Passing : 0]);
                data.addRow(['Failing', passFailData.Failing ? passFailData.Failing : 0]);
                data.addRow(['Other', passFailData.Other ? passFailData.Other : 0]);

                var options = {
                    backgroundColor: 'transparent',
                    pieHole: 0.7,
                    pieSliceText: 'value',
                    pieSliceTextStyle: {
                        color: 'black',
                        fontSize: 1,
                        opacity: 0, // hides text on chart
                        visibility: 'hidden'  // hides text on chart
                    },
                    tooltip: {
                        text: 'value'
                    },
                    chartArea: {
                        top: 30,
                        right: 25,
                        width: '100%',
                        height: '100%'
                    },
                    height: '100%',
                    width: '100%',
                    legend: {
                        position: 'left',
                        alignment: 'center',
                        maxLines: 2,
                        textStyle: {
                            color: '#003f7f',
                            fontSize: 12,
                            fontName: 'OpenSans-Regular',
                            marginBottom: 50,
                            bold: true,
                            toolText: '%'
                        }

                    },
                    colors: colors//,

                };

                var optionsMobile = {
                    backgroundColor: 'transparent',
                    pieHole: 0.7,
                    pieSliceText: 'value',
                    pieSliceTextStyle: {
                        color: 'black',
                        fontSize: 1,
                        opacity: 0, // hides text on chart
                        visibility: 'hidden'  // hides text on chart
                    },
                    tooltip: {
                        text: 'value'
                    },
                    chartArea: {
                        top: 30,
                        left: 0,
                        width: '100%',
                        height: 200
                    },
                    height: 400,
                    width: 270,
                    legend: {
                        position: 'bottom',
                        alignment: 'left',
                        maxLines: 1,
                        textStyle: {
                            color: '#003f7f',
                            fontSize: 12,
                            fontName: 'Open Sans',
                            bold: true,
                            toolText: '%'
                        }

                    },
                    colors: colors//,

                };

                var chart = new google.visualization.PieChart(angular.element('#pie-chart')[0]);
                chart.draw(data, options);

                var chartSmall = new google.visualization.PieChart(angular.element('#pie-chart-mobile')[0]);
                chartSmall.draw(data, optionsMobile);
            }
        }

        function getQueuedSamples() {
        	Sample.incompleteCount(function(data){
        		vm.queuedSamplesCount = data.count;

        	});
        }

        /* Date Utils (import DateUtils service doesn't work) */
        function convertLocalDateFromServer(date) {
    		if (!date) return;

    		if (date.indexOf('Z') === -1 && utcDateString.indexOf('+') === -1) utcDateString += 'Z';

            if (date) {
                var dateString = date.split('-');
                return new Date(dateString[0], dateString[1] - 1, dateString[2]);
            }
            return null;
        }

        function convertLocalDateToServer(date) {
            if (date) {
                return $filter('date')(date, 'yyyy-MM-dd');
            } else {
                return null;
            }
        }

        angular.element(document).ready(function() {


            setTimeout(function () {

                $("#table-payment tr:first > th").each(function(index, element) {
                    index++;
                    var addLi = "<li id='payment-dot-"+index+"' class='tbl-dot-" + index + "'><span  class='tbl-dot'></span></li>";
                    $('#dots-payment').append(addLi);
                });

                var num_col = $("#table-payment tr:first > th").length;
                var constant = 2;
                var constant2 = constant + 1;

                var col_width = 100 / num_col;
                $("#table-payment tr:first > th").css("width", col_width+"%");

                $("#payment-col-prev").click(function() {
                    constant -= 1;
                    constant2 -= 1;
                    if (constant == 2 || constant2 == 2) {
                        $("#payment-col-prev").attr('disabled', 'disabled');
                        $("#payment-col-next").prop("disabled", false);
                    }
                    $('.table-payment-col-' + constant).show();
                    $('.table-payment-col-' + constant2).show();
                    $('#payment-dot-' + constant).removeClass('disabled').addClass('active');
                    var x = constant2 + 1;
                    $('.table-payment-col-' + x).hide();
                    $('#payment-dot-' + x).removeClass('active').addClass('disabled');
                    if(constant == 2 || constant2 >= 2){
                        $("#payment-col-next").prop("disabled", false);
                    }
                });

                $("#payment-col-next").click(function() {
                    constant += 1;
                    constant2 += 1;
                    $('.table-payment-col-'  + constant).show();
                    $('.table-payment-col-'  + constant2).show();
                    $('#payment-dot-' + constant).removeClass('disabled').addClass('active');
                    $('#payment-dot-' + constant2).removeClass('disabled').addClass('active');
                    var x = constant - 1;
                    var x2 = constant2 - 1;
                    $('.table-payment-col-'  + x).hide();
                    $('#payment-dot-' + x).removeClass('active').addClass('disabled');
                    if (constant >= num_col || constant2 >= num_col) {
                        $("#payment-col-next").attr('disabled', 'disabled');
                        $("#payment-col-prev").prop("disabled", false);
                    }
                    $("#payment-col-prev").prop("disabled", false);
                });

            }, 1000);

            //orders table dots
            if(false) {
                setTimeout(function () {

                    $("#table-orders tr:first > th").each(function(index, element) {
                        index++;
                        var addLi = "<li id='orders-dot-"+index+"' class='tbl-dot-" + index + "'><span  class='tbl-dot'></span></li>";
                        $('#dots-orders').append(addLi);
                    });

                    var num_col = $("#table-orders tr:first > th").length;
                    var constant = 2;
                    var constant2 = constant + 1;

                    var col_width = 100 / num_col;
                    $("#table-orders tr:first > th").css("width", col_width+"%");

                    $("#orders-col-prev").click(function() {
                        constant -= 1;
                        constant2 -= 1;
                        if (constant == 2 || constant2 == 2) {
                            $("#orders-col-prev").attr('disabled', 'disabled');
                            $("#orders-col-next").prop("disabled", false);
                        }
                        $('.table-orders-col-' + constant).show();
                        $('.table-orders-col-' + constant2).show();
                        $('#orders-dot-' + constant).removeClass('disabled').addClass('active');
                        var x = constant2 + 1;
                        $('.table-orders-col-' + x).hide();
                        $('#orders-dot-' + x).removeClass('active').addClass('disabled');
                        if(constant == 2 || constant2 >= 2){
                            $("#orders-col-next").prop("disabled", false);
                        }
                    });

                    $("#orders-col-next").click(function() {
                        constant += 1;
                        constant2 += 1;
                        $('.table-orders-col-'  + constant).show();
                        $('.table-orders-col-'  + constant2).show();
                        $('#orders-dot-' + constant).removeClass('disabled').addClass('active');
                        $('#orders-dot-' + constant2).removeClass('disabled').addClass('active');
                        var x = constant - 1;
                        var x2 = constant2 - 1;
                        $('.table-orders-col-'  + x).hide();
                        $('#orders-dot-' + x).removeClass('active').addClass('disabled');
                        if (constant >= num_col || constant2 >= num_col) {
                            $("#orders-col-next").attr('disabled', 'disabled');
                            $("#orders-col-prev").prop("disabled", false);
                        }
                        $("#orders-col-prev").prop("disabled", false);
                    });

                }, 1000);
            }
        });
    }
})();
